<script>
import isNil from 'lodash-es/isNil'
import ui from '/~/core/ui'
import { formatDate } from '/~/utils/format/date'
import RecognitionHeader from '/~rec/components/recognition/recognition-header.vue'
import BaseHeader from '/~/components/base/header/base-header.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'
import EarnBlock from './components/earn-block.vue'

export default {
  name: 'rec-earn-post-modal',
  components: {
    RecognitionHeader,
    EarnBlock,
    BaseHeader,
    BaseMdl,
  },
  props: {
    earnConfigs: {
      type: Array,
      required: true,
    },
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    return {
      ui,
    }
  },
  computed: {
    numberOfQuizQuestions() {
      return this.post && this.post.questions
        ? this.post.questions.length
        : null
    },
    getMaxEarn() {
      let total = 0

      this.earnConfigs.forEach((action) => {
        if (isNil(action.earnValue)) return
        if (action.action === 'quiz_answer_create_correct') {
          total += Number(action.earnValue) * Number(this.numberOfQuizQuestions)
        } else {
          total += action.earnValue
        }
      })
      return total
    },
    endsAt() {
      return formatDate('daymonthyearnumeric', this.post.earnConfigsEndsAt)
    },
  },
}
</script>

<template>
  <base-mdl fullscreen="mobile" :width="ui.mobile ? 'screen' : 'xs'">
    <template #header>
      <base-header type="closable" class="text-white">
        <recognition-header icon="rec/round/token" color="warning" animated />
      </base-header>
    </template>
    <h3 class="mb-5 text-center text-2xl">
      Earn up to {{ getMaxEarn }}
      <span class="capitalize">
        {{ post.currency }}
      </span>
    </h3>
    <earn-block v-for="earn in earnConfigs" :key="earn.id" :earn="earn" />
    <div
      class="text-center text-xs font-bold leading-tight text-eonx-neutral-600"
    >
      <p v-if="endsAt">
        You can earn Points for this Post until {{ endsAt }} and there is a
        limit of {{ post.maxEarnValue }} Points. {{ post.earnedSoFar }} have
        already been earned from this Post. Once {{ post.maxEarnValue }} is
        reached, you will not be able to earn anymore Points.
      </p>
      <p v-else>
        There is a limit of {{ post.maxEarnValue }} Points for this Post.
        {{ post.earnedSoFar }} have already been earned. Once
        {{ post.maxEarnValue }} is reached, you will not be able to earn anymore
        Points.
      </p>
    </div>
  </base-mdl>
</template>
